<script setup lang="ts">
const {
    isEnabled: isFooterEnabled,
} = useFooterState();

provide('isSubFlow', true);
</script>

<template>
    <div>
        <TheBaseLayout>
            <div
                class="
                    grid
                    h-full
                    grid-rows-[min-content_auto_min-content]
                    bg-background-1
                "
            >
                <div class="sticky top-0 z-20 col-span-full row-span-1 row-start-1">
                    <slot name="header">
                        <TheSubHeader />
                    </slot>
                </div>

                <div class="col-span-full row-span-2 row-start-1">
                    <slot name="main">
                        <TheNuxtPage />
                    </slot>
                </div>

                <div :class="isFooterEnabled ? 'h-[68px] md:h-[76px]' : 'h-0'">
                    <slot name="footer">
                        <TheFooter />
                    </slot>
                </div>
            </div>
        </TheBaseLayout>
    </div>
</template>
